/* Version History
 * Static Base Version 1.3
 *  changed supported-by footer image sizing.
 * Static Base Version 1.2
 *  new font handling, import changes to:
 *    common-isrdi-extends.1.1
 *    common-isrdi-fonts.1.1 - removed as this should come in 
 *      from isrdi-common
 * Static Base Version 1.1
 *  support for left nav tweaks:
 *    reducing h3 headers in nav
 * Static Base Version 1.0 
 *  split static layout styling out from isrdi-common/ipums css
 *  added buttonbox-secondary and buttonbox-primary classes for buttonboxes
 */

@import 'common-isrdi-variables.1.0';
@import 'common-isrdi-extends.1.1';
@import 'base-ipums-variables.1.0';
// @import 'common-isrdi-fonts.1.1';

// ***
// * BEGIN Basic Layout
// ***
body > header {
  margin-top:$height_userbar;
}
nav[id="user_bar"] {
  position:absolute;
  width: 100vw;
  left:50%;
  right:50%;
  top:(-1 * ($height_userbar));
  height:$height_userbar;
  margin-left:-50vw;
  margin-right:-50vw;
  ul.list-menu {
    margin:0.6rem auto 0 auto;
    width:$width_content;    
  }
}
header,
body > header blockquote,
div[id="content"] {
  position:relative;
  width:$width_content;
  margin: 0 auto;
}
header {
  min-height:$height_header;
}
nav[id="multi_project_menu"] {
  position:absolute;
  top:1.7rem;
  left: 50%;
  width: 50%;
}
nav[id="topNav"] {
// navigation appearing at top right of page
  position:absolute;
  top:7.2rem;
  left:50%;
  width:50%;
}
nav[id="topNav"] + div[id="picturebar"],
nav[id="topNav"] + blockquote {
  margin-top:2.8rem;
}
nav[id="sidebar-left"] {
// generally contains the primary navigation
  float:left;
  width:$width_sidenav;
}
main, div[id="main"] {
  position:relative;
  box-sizing: border-box;
  overflow:auto;  // is this necessary?
  margin-left:$width_sidenav;
  padding-left:$width_gutter;
  padding-right:$width_gutter;
  padding-bottom:1rem;
  // pages without menus
  .no-menu & {
    margin-left:0;
  }

}
nav[id="sidebar-left"], .buttonbar, .subsection-links {
  h2 {
    @extend %sr-only;
  }
}
.width-full {
  header {
    width:auto;
    min-width:100%;
    padding-left:$width_gutter/2;

    nav[id="topNav"] {
      width:52.92rem;
    }

    div[id="picturebar"] {
      display:none;
    }
  }

  div[id="content"] {
    width:100%;
  }
  // make sure the sidebar is clickable over the padding
  nav[id="sidebar-left"] { 
    position:relative;
    z-index:10;
  }
  table {
    margin:1em 0;
  }
}
// ***
// * END Basic Layout
// ***

// ***
// * BEGIN Visual Styles for Page Structural Elements
// ***
html, body {
  // solid color will make header stripe
  background-image:linear-gradient(to bottom,$color_primary,$color_primary);
  background-size:100% $height_header;
  background-repeat:no-repeat;
}
nav[id="user_bar"] {
  background-color:$color_tertiary;
  text-transform: uppercase;
  text-align: right;
  font-size:1.2rem;
  line-height:1.2rem;
  p {
    margin:0.1em 0.4em;
  }
  ul.list-menu {
    // reverse the menu order
    flex-direction:row-reverse;
    li {
      &:first-child:before {
        content: "|";
        margin-right:0.5em;
      }
      &:last-child:before {
        content: none;
      }
    }
  }
  a {
    color:$color_near_white;    
  }
  .failed-login {
    display:inline;
    text-transform:lowercase;
    color:$color_secondary_med_dark;
  }
}
header {
  padding-top:2rem;
  background:  url("//assets.ipums.org/_img/bkg-Number-Bar-IPUMS.png") left ($height_header - 1.3rem) / auto 1.1rem repeat-x $color_primary;
}
div[id="siteLogo"] {
  a[href^="http"] {
    background:none;
    padding-right:0;
  }
  img {
    width:auto;
    height:7.4rem;
  }
}
nav[id="multi_project_menu"] {
  color: $color_primary;
  a {
    padding:0.2rem 0.8rem;

    color:$color_secondary_med_dark;
    background-color:$color_tertiary;
    &.current {
      color: $color_near_white;
      background-color:$color_primary;
    }
    &[href^="http"] {
      background-image:none;
      background-color:$color_tertiary;
      padding-right:0.8rem;
    }
    &:hover {
      text-decoration: none;
      color:$color_primary;
      background-color:rgba($color_near_white,0.5);
    }
  }
  ul {
    margin-top:0;
    margin-bottom:0;
    color:rgba(mix($color_primary,$color_near_white), .5);
  }
  p {
    font-size:1.7rem;
    line-height:1.7rem;
    font-family:'cabrito_sans_cond_bold';
    margin-top:0.5rem;
    text-transform:uppercase;
  }
  .list-menu {
    font-size:1.6rem;
    li {
      &:before {
        content:none;
      }
      a {
        margin-left:0;
      }
    }
  }
}
nav[id="topNav"] {
// navigation appearing at top right of page
  letter-spacing: 0.035em;
  border-top:1px $color_secondary_medium solid;

  ul {
    margin-top:0.7rem;
  }
  .list-menu li {
    color: $color_near_white;
    &:first-child {
      padding-left:0;
    }
    a {
      text-decoration: none;
      color: $color_near_white;
      &:hover {
        color:$color_primary;
      }
      &[href^="http"], &[href^="HTTP"] {
        background-image: url("//assets.ipums.org/_img/ico-external-link-white-40px.png");
        &.no-link-icon {
          @extend %no-link-icon;
        }
      }
    }
    text-transform:uppercase;
    font-size:1.4rem;
  }
}
div[id="picturebar"] {
// decorative pictures in the header 
  position:relative;
  display:flex;
  justify-content: space-between;
  background-color:$color_near_white;
  padding-top:$size_picturebar_border;
  padding-bottom:$size_picturebar_border;

  // double striped line after pictures
  &::after {
    position:absolute;
    content:"";
    z-index:10;
    bottom:-2*$size_picturebar_border;
    width:100%;
    height:2*$size_picturebar_border;
    background: linear-gradient(
      $color_primary,
      $color_primary $size_picturebar_border,
      $color_secondary_medium $size_picturebar_border,
      $color_secondary_medium 2*$size_picturebar_border);
  }

  div {
    background-color:$color_secondary_medium;
    width: $width_picture; //14.45rem;
    height: $height_picture; //9.65rem
    background-size:100% 100%;
    background-repeat:no-repeat;
  }
}
nav[id="sidebar-left"] {
  .no-menu & { display:none; }

  text-transform:uppercase;

  ul {
    margin-top:0;
    padding-left:0;
    padding-right:0;
    list-style:none;

    ul {
      padding-left:0;
      margin-top:0.4rem;
      margin-bottom:0.4rem;

      ul li.active > a {
        margin-left:-1 * $width_menu_indent;
        border-left:$width_menu_indent solid rgba($color_near_white, .5);
      }
    }
  }

  li, h3 {
    letter-spacing:0.05rem;
  }
  li {
    margin-top:1.3rem;

    &:first-child {
      margin-top:0;
    }
    &.active > a {
      color:$color_near_white;
      &:hover {
        color:$color_near_white;
      }
    }
    a { 
      position:relative;
      display:block;
      color: $color_secondary_dark;
      text-decoration: none;
      line-height:1.3rem;
      padding-left:$width_menu_indent;
      &[href^="http"] {
        display:inline-block;
        padding-right:1.3em;
      }

      &:hover {
        position:relative;
        color: $color_primary;

        &:before {
          position:absolute;
          left:0.8rem;
          content:"-";
        }
        &:not([href^="http"]):after {          
          content:"-";
          padding-left:0.3rem;
        }
      }
    }
    h3 {
      font-size: 1.5rem;
      padding-left:$width_menu_indent;
      margin-top:0;
      margin-bottom:0.4rem;
      a:hover {
        margin-left:-0.91rem;
      }
    }
    li {
      font-size:1.1rem;
      margin:0.6rem 0;
      letter-spacing:-0.02rem;
      line-height:1rem;
      h4 {
        font-size:1.2rem;
        border-bottom:none;
        margin-top:0.5rem;
        margin-left:0rem;
        a:hover {
          margin-left:-0.81rem;
        }
      }
      li {
        margin-left:$width_menu_indent;
      }
    }
  }
}
div[id="content"] {
  background-color:$color_near_white;
  background-image:  linear-gradient(to right, $color_secondary_light, $color_secondary_light $width_sidenav, $color_near_white $width_sidenav);
  padding-top:$height_top_padding;

  .no-menu & {
    background-image:none;
  }
}
section[id="supported-by"] {
  border-top:3px solid $color_primary;
  border-bottom:1px solid $color_secondary_medium;
  background-color:$color_near_white;
  text-align:center;  

  h2 {
    @include header-bars($color_secondary_medium,5rem, 0);
    font-size:$base_font_size;
    color:$color_secondary_dark;
  }

  p {
    margin:$width_gutter 0;

    a {
      @extend %no-link-icon;

      img {
        vertical-align: middle;
        height: 55px;
        width: auto;
        border: none;
        margin: 0rem 1.8rem;
        opacity: 0.6;
        filter: alpha(opacity=60);

        &.fixed-width {
          height: auto;
          width: 140px;
        }

        &.fixed-width-sm {
          height: auto;
          width: 110px;
        }

      }

      &:hover img {
        opacity:1;
        filter:alpha(opacity=100);
      }
    }
  }
}
footer {
  position:relative;
  font-size:0.8 * $base_font_size;
  color:$color_near_white;
  background-color:$color_primary;  
  text-align: center;
  padding-top:$width_gutter;
  padding-bottom:$width_gutter;
  letter-spacing: 0.05rem;
  a {
    &:link, &:visited {
      color:$color_secondary_medium;
    }
    &:hover, &:active {
      color:$color_near_white;
    }
    &[href^="http"], &[href^="HTTP"] {
      background-image: url("//assets.ipums.org/_img/ico-external-link-white-40px.png");
    }
  }

  nav {
    ul {
      justify-content: center;
    }
    a:link, a:visited {
      color:$color_near_white;
    }
    a:hover, a:active {
      color:$color_secondary_medium;
    }
  }
}
.width-full {
  header {
    background-repeat: repeat-x;
  }
}
// ***
// * END Visual Styles for Page Structural Elements
// ***

// ***
// * BEGIN General Tag Styling
// ***

// ***
// * END General Tag Styling
// ***

// ***
// * BEGIN Specialized Classes
// ***

// taglines shown on home pages
.tagline-home {
  text-align:center;
}

// link lists on the home pages
.subsection-links {
  font-size:$base-font-size*0.79;
  text-transform: uppercase;
  a {
    @extend %no-link-icon;
  }
}
// button boxes on the home pages
.buttonbar {
  margin: 4.4rem -2.5rem 0rem -2.3rem;
  display: flex;
}
.buttonbox {
  flex-grow: 1;
  flex-basis: 50%;
  background-color:$color_primary_dark;
  text-align:center;
  margin-right:0.4rem;

  h3 {
    @include header-bars;

    color:$color_near_white;
  }
  p {
    margin-top:$width_gutter * 0.75;
    margin-bottom:$width_gutter * 0.75;
  }
  a {
    @include button($color_primary, $color_near_white, $color_primary, $color_secondary_medium, $color_near_white );
    @extend %no-link-icon;
  }
  &:nth-child(3),
  &.buttonbox-secondary {
    background-color:$color_secondary_medium;
    a {
      &:link, &:visited {
        color: $color_near_white;
        background-color: $color_primary;
      }
      &:hover {
        color:$color_primary;
        background-color:$color_near_white;
      }
      &:active {
        border-color:$color_primary;
      }
    }
    h3 {
      color:$color_primary;
      &:before, &:after  {
        border-color:$color_primary;
      }
    }
  }
}
// callout boxes on the home pages
.callout {
  margin: 0 -2.5rem 0rem -2.3rem;
  padding:2.0rem 2.5rem;
  background-color:$color_bkg_med_light;
  h2 {
    font-size:($base-font-size*1.2);
    margin:0;
    padding:1px 0 0 0;
  }
  p {
    font-size:0.865*$base-font-size;
  }
}
// ***
// * END Specialized Classes
// ***



// ***
// * BEGIN responsive adaptations
// ***
// for some reason, we have to hard code width because $width_content 
// is rendering wrong for this one?
@media all and (max-width: 882px) {
  nav[id="user_bar"] {
    left:0;
    right:0;
    margin-left:0;
    margin-right:0;

    ul.list-menu {
      width:100%;
    }
  }
}
